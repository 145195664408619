@import "~@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/eot/Montserrat-Light.eot");
  src: local("Montserrat Light"), local("Montserrat-Light"), url("/assets/fonts/montserrat/eot/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/woff2/Montserrat-Light.woff2") format("woff2"), url("/assets/fonts/montserrat/woff/Montserrat-Light.woff") format("woff"), url("/assets/fonts/montserrat/ttf/Montserrat-Light.ttf") format("truetype"), url("/assets/fonts/montserrat/svg/Montserrat-Light.svg#Montserrat") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/eot/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("/assets/fonts/montserrat/eot/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/woff2/Montserrat-SemiBold.woff2") format("woff2"), url("/assets/fonts/montserrat/woff/Montserrat-SemiBold.woff") format("woff"), url("/assets/fonts/montserrat/ttf/Montserrat-SemiBold.ttf") format("truetype"), url("/assets/fonts/montserrat/svg/Montserrat-SemiBold.svg#Montserrat") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/eot/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("/assets/fonts/montserrat/eot/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/woff2/Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts/montserrat/woff/Montserrat-Bold.woff") format("woff"), url("/assets/fonts/montserrat/ttf/Montserrat-Bold.ttf") format("truetype"), url("/assets/fonts/montserrat/svg/Montserrat-Bold.svg#Montserrat") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/eot/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("/assets/fonts/montserrat/eot/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/woff2/Montserrat-Regular.woff2") format("woff2"), url("/assets/fonts/montserrat/woff/Montserrat-Regular.woff") format("woff"), url("/assets/fonts/montserrat/ttf/Montserrat-Regular.ttf") format("truetype"), url("/assets/fonts/montserrat/svg/Montserrat-Regular.svg#Montserrat") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/eot/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("/assets/fonts/montserrat/eot/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/montserrat/woff2/Montserrat-Medium.woff2") format("woff2"), url("/assets/fonts/montserrat/woff/Montserrat-Medium.woff") format("woff"), url("/assets/fonts/montserrat/ttf/Montserrat-Medium.ttf") format("truetype"), url("/assets/fonts/montserrat/svg/Montserrat-Medium.svg#Montserrat") format("svg");
  font-weight: 500;
  font-style: normal;
}
.ng-option {
  text-align: left;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  font-family: "Open Sans", sans-serif;
}

.table th {
  font-weight: bold;
  vertical-align: middle !important;
}

.th-20 {
  width: 20px !important;
}

.th-42 {
  width: 42px !important;
}

.th-88 {
  width: 88px !important;
}

.th-90 {
  width: 90px !important;
}

.th-100 {
  width: 100px !important;
}

.th-110 {
  width: 110px !important;
}

.th-120 {
  width: 120px !important;
}

.th-128 {
  width: 128px !important;
}

.th-140 {
  width: 140px !important;
}

.th-148 {
  width: 148px !important;
}

.th-150 {
  width: 150px !important;
}

.th-170 {
  width: 170px !important;
}

.th-188 {
  width: 188px !important;
}

.th-200 {
  width: 200px !important;
}

.th-210 {
  width: 210px !important;
}

.th-225 {
  width: 225px !important;
}

.th-235 {
  width: 235px !important;
}

.th-265 {
  width: 265px !important;
}

.m-form .m-form__table {
  padding: 2.2rem 2.2rem;
}

input.error,
.error .select2-selection,
textarea.error,
.error .ng-select-container,
.ng-touched.ng-invalid {
  border-color: #f4516c;
}

.row + .row {
  margin-top: 10px;
}

.btn ~ .btn {
  margin-left: 20px;
}